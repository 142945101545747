
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import { helper as $h } from "@/utils/helper";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      id: "user",
      title: "聯絡我們",
      canCreate: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "CreatedTime",
          title: "建立時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Name",
          title: "客戶姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Type",
          title: "問題類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.ContactUsType).find(
                  e => e.Value === cellValue
                )?.Name
              : undefined
        },
        {
          field: "ContactContent",
          title: "問題內容",
          formatter: ({ cellValue }) => cellValue?.substring(0, 20)
        }
      ],
      rowClassName(params) {
        return params.row.ReplyContent ? "" : "bg-indigo-100";
      },
      promises: {
        query: model
          ? params => model.dispatch("customerContact/query", params)
          : undefined,
        queryAll: model
          ? () => model.dispatch("customerContact/query")
          : undefined,
        save: model
          ? params => model.dispatch("customerContact/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "Name",
          title: "客戶姓名",
          span: 12,
          itemRender: { name: "$input", props: { disabled: true } }
        },
        {
          field: "MobilePhone",
          title: "行動電話",
          span: 12,
          itemRender: { name: "$input", props: { disabled: true } }
        },
        {
          field: "Email",
          title: "Email",
          span: 12,
          itemRender: { name: "$input", props: { disabled: true } }
        },
        // { field: "MemberId", title: "會員身分", span: 12, slots: { default: "column-member-id" } },
        {
          field: "Type",
          title: "問題類型",
          span: 12,
          itemRender: {
            name: "$select",
            props: { placeholder: "問題類型", disabled: true },
            options: model
              ? Object.values(model.enums.ContactUsType).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        {
          field: "ContactContent",
          title: "問題內容",
          span: 24,
          itemRender: { name: "$textarea", props: { disabled: true } }
        },
        {
          field: "ReplyContent",
          title: "回覆內容",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入回覆" }
          }
        }
      ],
      rules: {
        Title: [{ required: true }],
        CategoryId: [{ required: true }]
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
